import React from "react"
import styled from "styled-components"
import { Page } from "../../shared/Page/Page"
import { FlatButton } from "../../shared/Ui/FlatButton/FlatButton"
import media from "css-in-js-media"
import { Helmet } from "react-helmet"
//@ts-ignore
import ImgPat from "../../images/features/inbox/pat.jpg"
//@ts-ignore
import ImgJanet from "../../images/features/inbox/janet.jpg"
import { StaticImage } from "gatsby-plugin-image"

// @ts-ignore
import ImgHeroBg from "../../images/pricing/plans-bg.png"

const Container = styled.div`
    margin-bottom: 100rem;

    section.hero {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 70rem 0 100rem;
        flex-direction: column-reverse;
        background-image: url(${ImgHeroBg});
        background-size: cover;
        background-position: center bottom;

        ${media("<tablet")} {
            padding-bottom: 80rem;
            background: none;
        }

        .left {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            align-items: center;
            max-width: 750rem;

            .pre-header {
                display: flex;
                align-items: center;
                gap: 10rem;
                font-size: 14rem;
                color: ${({ theme }) => theme.text.lighter};
                font-family: "Gilroy-Regular", sans-serif;
                margin-bottom: 10rem;
            }

            h1 {
                font-size: 55rem;
                font-family: "Gilroy-Bold", sans-serif;
                color: ${({ theme }) => theme.text.dark};
                font-weight: unset;
                margin: 0;

                span {
                    background: -webkit-linear-gradient(#60a5fa, #818cf8);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            h2 {
                font-weight: normal;
                font-size: 20rem;
                color: rgb(76, 87, 115);
                line-height: 35rem;
                max-width: 550rem;
            }

            ${media("<phone")} {
                > h1:not(.desktop) {
                    font-size: 35rem;
                }

                > h2:not(.desktop) {
                    font-size: 20rem;
                }
            }
        }

        .right {
            flex: 1;
            display: flex;
            width: 100%;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 30rem;
            margin-bottom: 40rem;

            ${media("<desktop")} {
                display: none;
            }

            .anim {
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: center;
                gap: 20rem;

                > div {
                    display: flex;
                    flex-direction: column;
                    gap: 10rem;
                }

                .task-status {
                    color: ${({ theme }) => theme.text.light};
                    font-family: "Gilroy-Medium", sans-serif;
                    font-size: 14rem;
                }

                .task {
                    display: flex;
                    background: #fff;
                    box-shadow: rgba(149, 157, 165, 0.2) 0 8rem 24rem;
                    padding: 20rem 25rem;
                    border-radius: 10rem;
                    gap: 20rem;

                    .image {
                        border-radius: 90%;
                    }

                    .task-body {
                        display: flex;
                        flex-direction: column;
                        gap: 5rem;
                        width: 200rem;

                        color: ${({ theme }) => theme.text.light};

                        > div:first-of-type {
                            display: flex;
                            gap: 5rem;
                        }

                        .status {
                            padding: 5rem 10rem;
                            text-transform: uppercase;
                            font-size: 12rem;
                            font-weight: 600;
                            display: inline-flex;
                            border-radius: 5rem;

                            &.green {
                                background: #cbf4c9;
                                color: #0e6245;
                            }

                            &.red {
                                background: #fecdd3;
                                color: #be123c;
                            }

                            &.blue {
                                background: #bfdbfe;
                                color: #1d4ed8;
                            }

                            &.yellow {
                                background: #fef9c3;
                                color: #a16207;
                            }
                        }

                        .title {
                            margin: 5rem 0 0;
                            font-family: "Gilroy-SemiBold", sans-serif;

                            span {
                                color: ${({ theme }) => theme.primary};
                            }
                        }

                        .description {
                            font-size: 14rem;

                            span {
                                color: ${({ theme }) => theme.primary};
                            }
                        }
                    }
                }
            }
        }
    }

    section.quick-feats {
        display: flex;
        align-items: center;
        margin: 0 -20rem;
        padding: 70rem 20rem;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), transparent);

        > div {
            display: flex;
            gap: 90rem;

            ${media("<desktop")} {
                > div {
                    min-width: calc(50% - 40rem);
                }

                flex-wrap: wrap;
                gap: 50rem;
            }

            ${media("<=phone")} {
                flex-direction: column;

                > div:nth-of-type(even) {
                    min-width: unset;
                    align-items: flex-end;
                    text-align: right;
                }
            }

            > div {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 15rem;

                svg {
                    width: 50rem;
                    height: 50rem;
                }

                .title {
                    font-size: 20rem;
                    color: ${({ theme }) => theme.text.dark};
                    font-family: "Gilroy-SemiBold", sans-serif;
                }

                .description {
                    color: ${({ theme }) => theme.text.normal};
                    font-size: 15rem;
                    line-height: 30rem;
                }
            }
        }
    }
`

const clamp = (num, min, max) => Math.min(Math.max(num, min), max)

const FeatureInBrowser = ({ data, location }) => {
    return (
        <Page
            constrainedHeader
            constrainedFooter
            constrainedCta
            location={location}
        >
            <Helmet>
                <title>Tasks and planning</title>
            </Helmet>
            <Container>
                <section className={"hero pageConstrained"}>
                    <div className={"left"}>
                        <div className={"pre-header"}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="url(#brandGradient)"
                            >
                                <g>
                                    <rect
                                        fill="none"
                                        height="24"
                                        width="24"
                                        x="0"
                                    />
                                </g>
                                <g>
                                    <g>
                                        <path d="M19.46,8l0.79-1.75L22,5.46c0.39-0.18,0.39-0.73,0-0.91l-1.75-0.79L19.46,2c-0.18-0.39-0.73-0.39-0.91,0l-0.79,1.75 L16,4.54c-0.39,0.18-0.39,0.73,0,0.91l1.75,0.79L18.54,8C18.72,8.39,19.28,8.39,19.46,8z M11.5,9.5L9.91,6 C9.56,5.22,8.44,5.22,8.09,6L6.5,9.5L3,11.09c-0.78,0.36-0.78,1.47,0,1.82l3.5,1.59L8.09,18c0.36,0.78,1.47,0.78,1.82,0l1.59-3.5 l3.5-1.59c0.78-0.36,0.78-1.47,0-1.82L11.5,9.5z M18.54,16l-0.79,1.75L16,18.54c-0.39,0.18-0.39,0.73,0,0.91l1.75,0.79L18.54,22 c0.18,0.39,0.73,0.39,0.91,0l0.79-1.75L22,19.46c0.39-0.18,0.39-0.73,0-0.91l-1.75-0.79L19.46,16 C19.28,15.61,18.72,15.61,18.54,16z" />
                                    </g>
                                </g>
                            </svg>
                            Included free in 23shout
                        </div>
                        <h1>
                            Integrated <span>tasks</span> and{" "}
                            <span>planning</span> for the{" "}
                            <span>whole team</span>.
                        </h1>
                        <h2>
                            Attach to-do's and tasks directly to contacts and
                            visualise their state through team visible kan-ban
                            boards.
                        </h2>
                        <div>
                            <a href={"//app.23shout.com"} tabIndex={-1}>
                                <FlatButton color={"primary"} size={"larger"}>
                                    Get started for free <Chevron />
                                </FlatButton>
                            </a>
                        </div>
                    </div>
                    <div className={"right"}>
                        <div className={"anim"}>
                            <div>
                                <div className={"task-status"}>Todo</div>
                                <div className={"task"}>
                                    <StaticImage
                                        src={
                                            "../../images/features/inbox/janet.jpg"
                                        }
                                        alt={"Call scripts"}
                                        className={"image"}
                                        objectFit={"contain"}
                                        layout={"fixed"}
                                        width={30}
                                        height={30}
                                        placeholder={"none"}
                                        quality={100}
                                    />
                                    <div className={"task-body"}>
                                        <div>
                                            <div className={"status yellow"}>
                                                Todo
                                            </div>
                                        </div>
                                        <div className={"title"}>
                                            Re-schedule appointment with{" "}
                                            <span>Matthew</span>
                                        </div>
                                        <div className={"description"}>
                                            Moved due to COVID restrictions
                                        </div>
                                    </div>
                                </div>
                                <div className={"task"}>
                                    <StaticImage
                                        src={
                                            "../../images/features/inbox/pat.jpg"
                                        }
                                        alt={"Call scripts"}
                                        className={"image"}
                                        objectFit={"contain"}
                                        layout={"fixed"}
                                        width={30}
                                        height={30}
                                        placeholder={"none"}
                                        quality={100}
                                    />
                                    <div className={"task-body"}>
                                        <div>
                                            <div className={"status yellow"}>
                                                Todo
                                            </div>
                                            <div className={"status red"}>
                                                Overdue
                                            </div>
                                        </div>
                                        <div className={"title"}>
                                            Setup conference time in system
                                        </div>
                                        <div className={"description"}>
                                            ShoutCon invites should be included
                                            in this task.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className={"task-status"}>Doing</div>
                                <div className={"task"}>
                                    <StaticImage
                                        src={
                                            "../../images/features/inbox/pat.jpg"
                                        }
                                        alt={"Call scripts"}
                                        className={"image"}
                                        objectFit={"contain"}
                                        layout={"fixed"}
                                        width={30}
                                        height={30}
                                        placeholder={"none"}
                                        quality={100}
                                    />
                                    <div className={"task-body"}>
                                        <div>
                                            <div className={"status blue"}>
                                                In progress
                                            </div>
                                        </div>
                                        <div className={"title"}>
                                            Onboard <span>Harry</span>
                                        </div>
                                        <div className={"description"}>
                                            Scheduled for 12:30pm today
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className={"task-status"}>Completed</div>
                                <div className={"task"}>
                                    <StaticImage
                                        src={
                                            "../../images/features/inbox/pat.jpg"
                                        }
                                        alt={"Call scripts"}
                                        className={"image"}
                                        objectFit={"contain"}
                                        layout={"fixed"}
                                        width={30}
                                        height={30}
                                        placeholder={"none"}
                                        quality={100}
                                    />
                                    <div className={"task-body"}>
                                        <div>
                                            <div className={"status green"}>
                                                Done
                                            </div>
                                        </div>
                                        <div className={"title"}>
                                            Finalise ShoutCon participant list
                                        </div>
                                        <div className={"description"}>
                                            Attach guest list from MeetUp to
                                            calendar event
                                        </div>
                                    </div>
                                </div>
                                <div className={"task"}>
                                    <StaticImage
                                        src={
                                            "../../images/features/inbox/janet.jpg"
                                        }
                                        alt={"Call scripts"}
                                        className={"image"}
                                        objectFit={"contain"}
                                        layout={"fixed"}
                                        width={30}
                                        height={30}
                                        placeholder={"none"}
                                        quality={100}
                                    />
                                    <div className={"task-body"}>
                                        <div>
                                            <div className={"status green"}>
                                                Done
                                            </div>
                                        </div>
                                        <div className={"title"}>
                                            Book ShoutCon venue
                                        </div>
                                        <div className={"description"}>
                                            Speak to <span>David</span> and gain
                                            confirmation of booking
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={"quick-feats"}>
                    <div className={"pageConstrained"}>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="url(#brandGradient)"
                            >
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1s-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm0 4c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H6v-1.4c0-2 4-3.1 6-3.1s6 1.1 6 3.1V19z" />
                            </svg>
                            <div className={"title"}>Assign and track</div>
                            <div className={"description"}>
                                Attach tasks directly to contacts and track them
                                in your shared team kan-ban boards.
                            </div>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="url(#brandGradient)"
                            >
                                <g>
                                    <rect
                                        fill="none"
                                        height="24"
                                        width="24"
                                        x="0"
                                    />
                                </g>
                                <g>
                                    <g>
                                        <path d="M20.45,6l0.49-1.06L22,4.45c0.39-0.18,0.39-0.73,0-0.91l-1.06-0.49L20.45,2c-0.18-0.39-0.73-0.39-0.91,0l-0.49,1.06 L18,3.55c-0.39,0.18-0.39,0.73,0,0.91l1.06,0.49L19.55,6C19.72,6.39,20.28,6.39,20.45,6z" />
                                        <path d="M8.95,6l0.49-1.06l1.06-0.49c0.39-0.18,0.39-0.73,0-0.91L9.44,3.06L8.95,2C8.78,1.61,8.22,1.61,8.05,2L7.56,3.06L6.5,3.55 c-0.39,0.18-0.39,0.73,0,0.91l1.06,0.49L8.05,6C8.22,6.39,8.78,6.39,8.95,6z" />
                                        <path d="M19.55,13.5l-0.49,1.06L18,15.05c-0.39,0.18-0.39,0.73,0,0.91l1.06,0.49l0.49,1.06c0.18,0.39,0.73,0.39,0.91,0l0.49-1.06 L22,15.95c0.39-0.18,0.39-0.73,0-0.91l-1.06-0.49l-0.49-1.06C20.28,13.11,19.72,13.11,19.55,13.5z" />
                                        <path d="M17.71,9.12l-2.83-2.83c-0.39-0.39-1.02-0.39-1.41,0L2.29,17.46c-0.39,0.39-0.39,1.02,0,1.41l2.83,2.83 c0.39,0.39,1.02,0.39,1.41,0l11.17-11.17C18.1,10.15,18.1,9.51,17.71,9.12z M14.21,11.21l-1.41-1.41l1.38-1.38l1.41,1.41 L14.21,11.21z" />
                                    </g>
                                </g>
                            </svg>
                            <div className={"title"}>Automation heaven</div>
                            <div className={"description"}>
                                Automatically create tasks for teams and
                                customers when calls end in a certain way,
                                reducing the cognitive complexity of your team
                                environment.
                            </div>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="url(#brandGradient)"
                            >
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path d="M3 17h18c.55 0 1 .45 1 1s-.45 1-1 1H3c-.55 0-1-.45-1-1s.45-1 1-1zm10.84-9.21c.1-.24.16-.51.16-.79 0-1.1-.9-2-2-2s-2 .9-2 2c0 .28.06.55.16.79C6.25 8.6 3.27 11.93 3 16h18c-.27-4.07-3.25-7.4-7.16-8.21z" />
                            </svg>
                            <div className={"title"}>Fully featured</div>
                            <div className={"description"}>
                                When you add due dates, assignees, descriptions,
                                custom statuses, and live updates into the mix{" "}
                                there's now one less tab to keep open.
                            </div>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="url(#brandGradient)"
                            >
                                <rect fill="none" height="24" width="24" />
                                <g>
                                    <path d="M19.83,7.5l-2.27-2.27c0.07-0.42,0.18-0.81,0.32-1.15c0.11-0.26,0.15-0.56,0.09-0.87C17.84,2.49,17.14,1.99,16.4,2 c-1.59,0.03-3,0.81-3.9,2l-5,0C4.46,4,2,6.46,2,9.5c0,2.25,1.37,7.48,2.08,10.04C4.32,20.4,5.11,21,6.01,21L8,21c1.1,0,2-0.9,2-2v0 h2v0c0,1.1,0.9,2,2,2l2.01,0c0.88,0,1.66-0.58,1.92-1.43l1.25-4.16l2.14-0.72c0.41-0.14,0.68-0.52,0.68-0.95V8.5c0-0.55-0.45-1-1-1 H19.83z M12,9H9C8.45,9,8,8.55,8,8v0c0-0.55,0.45-1,1-1h3c0.55,0,1,0.45,1,1v0C13,8.55,12.55,9,12,9z M16,11c-0.55,0-1-0.45-1-1 c0-0.55,0.45-1,1-1s1,0.45,1,1C17,10.55,16.55,11,16,11z" />
                                </g>
                            </svg>
                            <div className={"title"}>Provided gratis</div>
                            <div className={"description"}>
                                No added costs or hidden fees, tasks are
                                provided on us without charge.
                            </div>
                        </div>
                    </div>
                </section>
            </Container>
        </Page>
    )
}

const Chevron = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="currentColor"
            className={"bottom-link-chevron"}
        >
            <path d="M9.29 6.71c-.39.39-.39 1.02 0 1.41L13.17 12l-3.88 3.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41L10.7 6.7c-.38-.38-1.02-.38-1.41.01z" />
        </svg>
    )
}

export default FeatureInBrowser
